import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import Style from './Header.module.scss';

import { UnorderedListOutlined } from '@ant-design/icons';

import logoMitra from '../../Assets/icons/mitraLogo.png';

function Header() {
	const [drpdwnOpen, setDrpdwnOpen] = React.useState(false);

	return (
		<header className={Style.header}>
			<div className={Style.header__container}>
				<nav className={Style.header__nav}>
					<ul className={Style.header__list}>
						<li>
							<Link to="https://viomitra.ru/">
								<img className={Style.header__list_logo} src={logoMitra} alt="VioMitra" />
							</Link>
						</li>
						<li
							className={Style.header__list_item}
							onMouseEnter={() => {
								setDrpdwnOpen(true);
							}}
							onMouseLeave={() => {
								setDrpdwnOpen(false);
							}}>
							<NavLink to="/products" className={Style.animated_button_prod}>
								<span>
									<UnorderedListOutlined style={{ marginRight: 10 }} />
									Продукты
								</span>
								<span></span>
							</NavLink>

							<ul
								className={
									drpdwnOpen ? Style.header__dropdown_list : Style.header__dropdown_list_close
								}>
								<li className={Style.header__dropdown_item}>
									<NavLink to="/products/mitra127">
										<span>Mitra 127</span>
									</NavLink>
								</li>
								<li className={Style.header__dropdown_item}>
									<NavLink to="/products/mitra229">
										<span>Mitra 229</span>
									</NavLink>
								</li>
								<li className={Style.header__dropdown_item}>
									<NavLink to="/products/mitra-comm">
										<span>Mitra Comm</span>
									</NavLink>
								</li>
								<li className={Style.header__dropdown_item}>
									<NavLink to="/products/viomitra-crm">
										<span>Viomitra CRM</span>
									</NavLink>
								</li>
							</ul>
						</li>
						<li className={Style.header__list_item}>
							<NavLink to="/trydemo" className={Style.animated_button}>
								<span>Попробовать Демо</span>
								<span></span>
							</NavLink>
						</li>
						<li className={Style.header__list_item}>
							<NavLink to="/offers" className={Style.animated_button}>
								<span>Услуги</span>
								<span></span>
							</NavLink>
						</li>
						<li className={Style.header__list_item}>
							<NavLink to="/prices" className={Style.animated_button}>
								<span>Цены</span>
								<span></span>
							</NavLink>
						</li>
						<li className={Style.header__list_item}>
							<NavLink to="/feedback" className={Style.animated_button}>
								<span>Обратная связь</span>
								<span></span>
							</NavLink>
						</li>
						<li className={Style.header__list_item}>
							<NavLink to="/aboutus" className={Style.animated_button}>
								<span>О нас</span>
								<span></span>
							</NavLink>
						</li>
					</ul>
				</nav>
			</div>
		</header>
	);
}

export default Header;
