import React from 'react';

import Style from './HeaderMobile.module.scss';

export const HeaderMobile = ({ onOpenMenu }) => {
	return (
		<header className={Style.header}>
			<button className={Style.btn} onClick={onOpenMenu}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="35"
					height="20"
					viewBox="0 0 35 20"
					fill="none">
					<rect width="35" height="4" rx="2" fill="#262442" />
					<rect y="8" width="35" height="4" rx="2" fill="#262442" />
					<rect y="16" width="35" height="4" rx="2" fill="#262442" />
				</svg>
			</button>
		</header>
	);
};
