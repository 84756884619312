import React from 'react';
import { Link } from 'react-router-dom';

import { UnorderedListOutlined, CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';

import Style from './MobileMenu.module.scss';
import mitraLogoSVG from '../../Assets/icons/mitraLogo.png';

export const MobileMenu = ({ isMenuOpen, onCloseMenu }) => {
	const [isShowDropdown, setIsShowDropdown] = React.useState(false);
	const onOpenDrop = () => {
		setIsShowDropdown(true);
	};
	const onCloseDrop = () => {
		setIsShowDropdown(false);
	};
	return (
		<div className={isMenuOpen ? Style.menu : Style.menu_close}>
			<nav className={Style.nav}>
				<button className={Style.btn} onClick={onCloseMenu}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="29"
						height="31"
						viewBox="0 0 29 31"
						fill="none">
						<path d="M2 28L27 3" stroke="white" strokeWidth="4" strokeLinecap="round" />
						<path d="M27 28L2 3" stroke="white" strokeWidth="4" strokeLinecap="round" />
					</svg>
				</button>
				<ul className={Style.list}>
					<li className={Style.list__item}>
						<Link to="/products">
							<UnorderedListOutlined /> Продукты{' '}
						</Link>
						{!isShowDropdown ? (
							<CaretRightOutlined onClick={onOpenDrop} />
						) : (
							<CaretDownOutlined onClick={onCloseDrop} />
						)}
						{isShowDropdown && (
							<ul className={Style.droplist}>
								<li className={Style.droplist__item}>
									<Link to="/products/mitra127">Mitra 127</Link>
								</li>
								<li className={Style.droplist__item}>
									<Link to="/products/mitra229">Mitra 229</Link>
								</li>
								<li className={Style.droplist__item}>
									<Link to="/products/mitra-comm">Mitra comm</Link>
								</li>
								<li className={Style.droplist__item}>
									<Link to="/products/viomitra-crm">Viomitra CRM</Link>
								</li>
							</ul>
						)}
					</li>
					<li className={Style.list__item}>
						<Link to="/trydemo">Попробовать Демо</Link>
					</li>
					<li className={Style.list__item}>
						<Link to="/offers">Услуги</Link>
					</li>
					<li className={Style.list__item}>
						<Link to="/prices">Цены</Link>
					</li>
					<li className={Style.list__item}>
						<Link to="/feedback">Обратная связь</Link>
					</li>
					<li className={Style.list__item}>
						<Link to="/aboutus">О нас</Link>
					</li>
					<li className={Style.list__item}>
						<Link to="https://viomitra.ru/">
							<img src={mitraLogoSVG} alt="" />
						</Link>
					</li>
				</ul>
			</nav>
		</div>
	);
};
