import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import Header from './Components/Header';
import Footer from './Components/Footer';

import './Scss/style.scss';

import { useResize } from './Hooks/useResize';
import { HeaderMobile } from './Components/HeaderMobile';
import { MobileMenu } from './Components/MobileMenu';

const Home = React.lazy(() => import('./Pages/Home'));
const Products = React.lazy(() => import('./Pages/Products'));
const TryDemo = React.lazy(() => import('./Pages/TryDemo'));
const Offers = React.lazy(() => import('./Pages/Offers'));
const Prices = React.lazy(() => import('./Pages/Prices'));
const AboutUs = React.lazy(() => import('./Pages/AboutUs'));
const Feedback = React.lazy(() => import('./Pages/Feedback'));

const Mitra = React.lazy(() => import('./Pages/Mitra'));
const Mitra127 = React.lazy(() => import('./Pages/Mitra127'));
const Mitra229 = React.lazy(() => import('./Pages/Mitra229'));
const MitraComm = React.lazy(() => import('./Pages/MitraComm'));
const MitraCRM = React.lazy(() => import('./Pages/MitraCRM'));

const CallCenter = React.lazy(() => import('./Pages/CallCenter'));
const Accredit = React.lazy(() => import('./Pages/Accredit'));
const Support = React.lazy(() => import('./Pages/Support'));
const FollowAdmin = React.lazy(() => import('./Pages/FollowAdmin'));

function App() {
	//useResize кастом хук для отслеживания размеров окна
	const { isScreenLg } = useResize();
	//state открытия меню
	const [isMenuOpen, setIsMenuOpen] = React.useState(false);

	const onOpenMenu = () => {
		setIsMenuOpen(true);
	};
	const onCloseMenu = () => {
		setIsMenuOpen(false);
	};
	return (
		<div className="App">
			{isScreenLg ? (
				<Header />
			) : (
				<>
					<HeaderMobile onOpenMenu={onOpenMenu} isMenuOpen={isMenuOpen} />
					<MobileMenu isMenuOpen={isMenuOpen} onCloseMenu={onCloseMenu} />
				</>
			)}

			<Routes>
				<Route
					path="/"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<Home />
						</Suspense>
					}
				/>
				<Route
					path="/products"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<Products />
						</Suspense>
					}
				/>
				<Route
					path="/products/mitra127"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<Mitra127 />
						</Suspense>
					}
				/>
				<Route
					path="/products/mitra229"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<Mitra229 />
						</Suspense>
					}
				/>
				<Route
					path="/products/mitra-comm"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<MitraComm />
						</Suspense>
					}
				/>
				<Route
					path="/products/viomitra-crm"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<MitraCRM />
						</Suspense>
					}
				/>

				<Route
					path="/mitra"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<Mitra />
						</Suspense>
					}
				/>

				<Route
					path="/trydemo"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<TryDemo />
						</Suspense>
					}
				/>
				<Route
					path="/offers"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<Offers />
						</Suspense>
					}
				/>
				<Route
					path="/prices"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<Prices />
						</Suspense>
					}
				/>
				<Route
					path="/feedback"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<Feedback />
						</Suspense>
					}
				/>
				<Route
					path="/aboutus"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<AboutUs />
						</Suspense>
					}
				/>

				<Route
					path="/offers/callcenter"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<CallCenter />
						</Suspense>
					}
				/>
				<Route
					path="/offers/tech-support"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<Support />
						</Suspense>
					}
				/>
				<Route
					path="/offers/follow-admin"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<FollowAdmin />
						</Suspense>
					}
				/>
				<Route
					path="/offers/accreditation"
					element={
						<Suspense
							fallback={
								<div className="loader__container">
									<div className="loader"></div>
								</div>
							}>
							<Accredit />
						</Suspense>
					}
				/>
			</Routes>

			<Footer />
		</div>
	);
}

export default App;
