import React from 'react';
import { Link } from 'react-router-dom';

import Style from './Footer.module.scss';

import logoMitra from '../../Assets/icons/mitraLogo.png';

function Footer() {
	return (
		<footer className={Style.footer}>
			<div className={Style.container}>
				<nav className={Style.nav}>
					<ul className={Style.list}>
						<li className={Style.list_item}>
							<Link to="/">
								<img className={Style.list_logo} src={logoMitra} alt="VioMitra" />
							</Link>
						</li>
						<li className={Style.list_item}>
							<Link to="/products">
								<span>Продукты</span>
							</Link>
						</li>
						<li className={Style.list_item}>
							<Link to="/trydemo">Попробовать Демо</Link>
						</li>
						<li className={Style.list_item}>
							<Link to="/offers">Услуги</Link>
						</li>
						<li className={Style.list_item}>
							<Link to="/prices">Цены</Link>
						</li>
						<li className={Style.list_item}>
							<Link to="/feedback">Обратная связь</Link>
						</li>
						<li className={Style.list_item}>
							<Link to="/aboutus">О нас</Link>
						</li>
					</ul>
				</nav>
			</div>
		</footer>
	);
}

export default Footer;
